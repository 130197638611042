import { http, httpNoAuth } from "..";
import { getPasskeysFilter, userSignInPayload } from "./auth-interface";

export const signin = (data: userSignInPayload) => {
  return httpNoAuth.post("/auth/login", data);
};

export const passkeyRegisterOptions = (data: { userId: string }) => {
  return http.post("/auth/passkey/register-options", data);
};

export const passkeyRegisterVerify = (data: any) => {
  return http.post("/auth/passkey/register-verify", data);
};

export const passkeyAuthOptions = (data: { email: string }) => {
  return httpNoAuth.post("/auth/passkey/generate-authentication-options", data);
};

export const passkeyAuthVerify = (data: any) => {
  return httpNoAuth.post("/auth/passkey/verify-authentication", data);
};


export const getPasskeys = async (filter?: getPasskeysFilter) => {
  return await http.get('/auth/passkey/get-passkeys', {
    params: {
      ...filter,
    },
  });
};