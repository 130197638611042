import React from "react";

const Privacy = () => {
  return (
    <div className="px-6 pb-12 linear-bg-one lg:px-8 overscroll-y">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="w-auto h-12 mx-auto"
          src="/img/logo/logo.png"
          alt="Your Company"
        />

        <h2 className="mt-2 text-xl font-bold leading-9 tracking-tight text-center text-gray-900">
          Privacy & Policy
        </h2>
      </div>

      <main className="flex-grow p-4 overflow-y-auto">
        <div className="max-w-3xl mx-auto">
          <h2 className="mb-4 text-2xl font-bold">Privacy Policy</h2>

          <section className="mb-6">
            <h3 className="mb-2 text-xl font-semibold">Introduction</h3>
            <p className="mb-4 text-gray-700">
              Desi Bazaar ("we", "our", or "us") is committed to protecting your
              privacy and ensuring your personal information is handled in a
              safe and responsible manner. This Privacy Policy outlines how we
              collect, use, and protect your personal information when you use
              our mobile app to browse, order, and purchase clothes.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="mb-2 text-xl font-semibold">
              Information We Collect
            </h3>
            <p className="mb-2 text-gray-700">
              We collect and store the following types of information when you
              use the Desi Bazaar app:
            </p>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              1. Personal Information
            </h4>
            <ul className="pl-6 mb-4 text-gray-700 list-disc">
              <li>Name</li>
              <li>Email Address</li>
              <li>Contact Number</li>
              <li>Shipping Address</li>
              <li>Billing Information</li>
            </ul>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              2. Usage Information
            </h4>
            <ul className="pl-6 mb-4 text-gray-700 list-disc">
              <li>IP Address</li>
              <li>Device Information</li>
              <li>Browsing History</li>
              <li>App usage data</li>
            </ul>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              3. Payment Information
            </h4>
            <p className="text-gray-700">
              We use third-party payment processors (such as Zelle) to handle
              your financial transactions. We do not store your credit card or
              other financial information.
            </p>
          </section>

          <section className="mb-6">
            <h3 className="mb-2 text-xl font-semibold">
              How We Use Your Information
            </h3>
            <p className="mb-2 text-gray-700">
              We use the collected information for the following purposes:
            </p>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              1. Order Fulfillment
            </h4>
            <p className="mb-4 text-gray-700">
              To process your orders, deliver products, and communicate with you
              about your orders.
            </p>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              2. Customer Service
            </h4>
            <p className="mb-4 text-gray-700">
              To respond to your inquiries, provide customer support, and
              address any issues.
            </p>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              3. Personalization
            </h4>
            <p className="mb-4 text-gray-700">
              To enhance your shopping experience by displaying products and
              recommendations that may interest you.
            </p>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              4. Marketing and Notifications
            </h4>
            <p className="mb-4 text-gray-700">
              With your consent, we may send you promotional offers, updates,
              and newsletters. You can opt out of marketing communications at
              any time.
            </p>
            <h4 className="mt-4 mb-2 text-lg font-semibold">
              5. Analytics and Improvements
            </h4>
            <p className="mb-4 text-gray-700">
              To analyze app performance and usage, as well as to improve our
              services and products.
            </p>
          </section>

          {/* Add more sections here following the same pattern */}

          <section className="mb-6">
            <h3 className="mb-2 text-xl font-semibold">Contact Us</h3>
            <p className="text-gray-700">
              If you have any questions regarding this Privacy Policy, please
              contact us at:{" "}
              <a className="font-semibold" href="mailto:info@desibazaarapp.com">info@desibazaarapp.com</a>
              .
            </p>
          </section>
        </div>
      </main>
    </div>
  );
};

export default Privacy;
