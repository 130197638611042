import { Alert, Button, Form, Input } from "antd";
import React, { useState } from "react";
import { userSignInPayload } from "../../../api/auth/auth-interface";
import {
  passkeyAuthOptions,
  passkeyAuthVerify,
  signin,
} from "../../../api/auth/auth";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../store/userSlice/userSlice";
import { useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../../../utils/helper";
import { startAuthentication } from "@simplewebauthn/browser";

const Adminauth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loadingWithPassKey, setLoadingWithPassKey] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const onFinish = async (values: userSignInPayload) => {
    try {
      setError(null);
      setLoading(true);

      const res = await signin({
        email: values.email,
        password: values.password,
      });

      const { message, token } = res.data;
      const user: any = jwtDecode(token);

      dispatch(
        loginUser({
          token,
          user,
        })
      );

      setLoading(false);
      openNotificationWithIcon("success", message);
      navigate("/admin/dashboard");
    } catch (error: any) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";

      const resErrors = error?.response?.data?.errors || {};
      if (Object.keys(resErrors).length > 0) {
        setErrors((prevState) => ({
          ...prevState,
          ...resErrors,
        }));
      } else {
        setError(errorMessage);
      }
    }
  };

  const loginWithPasskey = async () => {
    const email = form.getFieldValue("email");
    try {
      setError(null);
      setLoadingWithPassKey(true);

      if (email === undefined || email === null || email.trim().length === 0) {
        //@ts-ignore
        setError("Provide valid email address");
        setLoadingWithPassKey(false);
        return false;
      }

      const optionsRes = await passkeyAuthOptions({
        email,
      });

      const { success, options, userId } = optionsRes.data;
      if (!success) {
        throw new Error("Something went wrong");
      }

      const asseResp = await startAuthentication({ optionsJSON: options });

      const verificationResp = await passkeyAuthVerify({
        userId,
        asseResp,
      });

      const { message, token } = verificationResp.data;
      const user: any = jwtDecode(token);

      dispatch(
        loginUser({
          token,
          user,
        })
      );

      setLoadingWithPassKey(false);
      openNotificationWithIcon("success", message);
      navigate("/admin/dashboard");
    } catch (error: any) {
      setLoadingWithPassKey(false);
      const errorMessage =
        error?.response?.data?.message || error?.message || "Something went wrong";

      const resErrors = error?.response?.data?.errors || {};
      if (Object.keys(resErrors).length > 0) {
        setErrors((prevState) => ({
          ...prevState,
          ...resErrors,
        }));
      } else {
        setError(errorMessage);
      }
    }
  };

  return (
    <div className="flex flex-col justify-center flex-1 h-screen px-6 pb-12 bg-gradient-to-br from-pink-400 via-purple-500 to-indigo-600 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="w-auto h-16 mx-auto"
          src="/img/logo/logo.png"
          alt="Your Company"
        />
        {/* <h2 className="mt-2 text-2xl font-bold leading-9 tracking-tight text-center text-gray-900">
          Sign in to your account
        </h2> */}
      </div>

      <div className="p-8 mt-5 shadow-lg max-xs:w-full xs:w-[80%] sm:w-[50%] md:w-[30%] mx-auto bg-white rounded-md">
        <h1 className="mb-2 text-2xl font-bold text-gray-800">
          Sign in to your account
        </h1>

        {error != null && (
          <Alert message={error} type="error" className="w-full mb-4" />
        )}

        <p className="mb-3 opacity-[0.9]">
          To sign in, please type in your correct login credentials.
        </p>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "Invalid Email",
              },
            ]}
            validateStatus={errors?.email.length ? "error" : undefined}
            help={errors?.email.length ? errors?.email : undefined}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
            validateStatus={errors?.email.length ? "error" : undefined}
            help={errors?.email.length ? errors?.email : undefined}
          >
            <Input.Password placeholder="Password" type="password" />
          </Form.Item>
          <Form.Item>
            <div>
              <Button
                loading={loading}
                disabled={loading || loadingWithPassKey}
                htmlType="submit"
              >
                Submit
              </Button>

              <Button
                type="primary"
                className="ml-2 bg-appColorBlue2"
                loading={loadingWithPassKey}
                disabled={loading || loadingWithPassKey}
                onClick={() => {
                  loginWithPasskey();
                }}
              >
                Login With Passkey
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Adminauth;
