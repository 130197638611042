import React, { useCallback, useEffect, useMemo, useState } from "react";
import { product } from "../../../../customhooks/useProducts/useProducts";
import { Alert, Button, Table, TableProps, Tag } from "antd";
import moment from "moment";
import { openNotificationWithIcon } from "../../../../utils/helper";
import {
  getQuestionsFilter,
  question,
} from "../../../../api/base/base-interface";
import {
  fetchQuestions,
  markQuestionAsClosed,
} from "../../../../api/base/base";

const Questions = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<question[]>([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [workingQuestion, setWorkingQuestion] = useState<question | null>(null);
  const [workingQuestionAction, setWorkingQuestionAction] = useState(false);

  const requestPayload: getQuestionsFilter = useMemo(() => {
    return {
      page: currentPage,
      limit: currentLimit,
      adminUse: true,
    };
  }, [currentPage, currentLimit]);

  const runFetchQuestion = useCallback(async () => {
    console.log(requestPayload);
    try {
      setLoading(true);
      const res = await fetchQuestions(requestPayload);
      const { docs, totalDocs } = res.data?.data;
      setTotalDocs(totalDocs);
      setQuestions(docs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // eslint-disable-next-line
  }, [requestPayload]);

  useEffect(() => {
    runFetchQuestion();
  }, [runFetchQuestion]);

  const runMarkQuestionAsClosed = async (item: question) => {
    try {
      setWorkingQuestionAction(true);
      const res = await markQuestionAsClosed({
        questionId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Mark Question As Closed", message);
      setWorkingQuestionAction(false);
      runFetchQuestion();
      setWorkingQuestion(null);
    } catch (error: any) {
      setWorkingQuestionAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon(
        "error",
        "Mark Question As Closed",
        errorMessage
      );
    }
  };

  const columns: TableProps<question>["columns"] = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text: product) => (
        <span className="capitalize" onClick={() => {}}>
          {text?.name}
        </span>
      ),
    },
    {
      title: "Whatsapp Msg Delivered",
      dataIndex: "messageDetails",
      key: "messageDetails",
      render: (text: {
        from: string;
        to: string;
        provider: string;
        service: string;
        accountSid: string;
        messagingServiceSid: null;
        sid: string;
        status: string;
      }) => (
        <div>
          {text ? (
            <Tag color="green">Delivered</Tag>
          ) : (
            <Tag color="red">Not Delivered</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <div>
          {text === "closed" ? (
            <Tag color="green">Closed</Tag>
          ) : (
            <Tag color="cyan">Open</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item: question) =>
        item?.status === "open" ? (
          <div className="flex items-center gap-2">
            <Button
              onClick={() => {
                setWorkingQuestion(item);
                runMarkQuestionAsClosed(item);
              }}
              type="primary"
              className="bg-appColorBlue2"
              size="small"
              disabled={workingQuestionAction}
              loading={
                workingQuestion?._id === item?._id && workingQuestionAction
              }
            >
              Mark as closed
            </Button>
          </div>
        ) : (
          "-"
        ),
      fixed: "right",
    },
  ];

  return (
    <div className="mt-4">
      <Table
        size="small"
        dataSource={questions}
        columns={columns}
        loading={loading}
        rowKey={(record) => record._id}
        pagination={{
          total: totalDocs,
          pageSize: currentLimit,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentLimit(pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} questions`,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="pl-12">
              <Alert
                // message="Message"
                description={
                  <>
                    <div>
                      <p>
                        <b>Message: </b>
                        {record?.message}
                      </p>
                    </div>
                    {record?.product && (
                      <div className="mt-8">
                        <div>
                          <p className="font-bold">Product Details</p>
                          <p>
                            <b>Name:</b> {record?.product?.name}
                          </p>
                          <p>
                            <b>ID:</b> {record?.product?.selfId}
                          </p>
                          <p>
                            <b>Selling Price ($):</b>{" "}
                            {record?.product?.sellingPrice ?? "-"}
                          </p>
                          <p>
                            <b>Buying Price ($):</b>{" "}
                            {record?.product?.buyingPrice ?? "-"}
                          </p>
                          <p>
                            <b>Final Sell Price ($):</b>{" "}
                            {record?.product?.price ?? "-"}
                          </p>
                          <p>
                            <b>Product Link:</b>{" "}
                            <a
                              href={`https://desibazaarapp.com/product/${record?.product?.selfId}`}
                            >
                              {`https://desibazaarapp.com/product/${record?.product?.selfId}`}
                            </a>
                          </p>
                        </div>

                        <div className="mt-8">
                          <p className="font-bold">Seller Details</p>
                          <p>
                            <b>Name:</b> {record?.product?.createdBy?.lastName}{" "}
                            {record?.product?.createdBy?.firstName}
                          </p>
                          <p>
                            <b>Email:</b> {record?.product?.createdBy?.email}
                          </p>
                          <p>
                            <b>Phone:</b>{" "}
                            {record?.product?.createdBy?.phoneNumber}
                          </p>
                          <p>
                            <b>Instagram Handle:</b>{" "}
                            {record?.product?.createdBy?.instagramHandle}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                }
                type="info"
              />
            </div>
          ),
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default Questions;
