import React, { useEffect, useState } from "react";
import { Apple, ShoppingBag, Smartphone } from "lucide-react";
import { useParams } from "react-router-dom";

const IOSUpdateUrl =
  "https://apps.apple.com/us/app/desi-bazaar-app/id6502737877";
const AndroidUpdateUrl =
  "https://play.google.com/store/apps/details?id=com.desibazaar.app";

const AppPromotion: React.FC = () => {
  const [deviceType, setDeviceType] = useState<"ios" | "android" | "other">(
    "other"
  );
  const params = useParams();

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    let actualDevice: "ios" | "android" | "other";
    if (/iphone|ipad|ipod/.test(userAgent)) {
      actualDevice = "ios";
      setDeviceType("ios");
    } else if (/android/.test(userAgent)) {
      actualDevice = "android";
      setDeviceType("android");
    } else {
      actualDevice = "other";
      setDeviceType("other");
    }

    // Function to attempt to open the app
    const openApp = () => {
      let deepLink;
      if (deviceType === "ios") {
        deepLink = params?.id
          ? `desibazaarapp://product/${params.id}`
          : "desibazaarapp://open";
      } else if (deviceType === "android") {
        // Android deep link format
        deepLink = params?.id
          ? `intent://desibazaarapp/product/${params.id}#Intent;scheme=https;package=com.desibazaar.app;end`
          : `intent://desibazaarapp#Intent;scheme=https;package=com.desibazaar.app;end`;
      }

      // Attempt to open the app
      try {
        if (deepLink) {
          window.location.href = deepLink;
        }

        // Set a timeout to check if the app opened
        const timeout = setTimeout(() => {
          // If the app hasn't opened, alert the user and redirect to the app store
          if (actualDevice === "android" || actualDevice === "ios") {
            const storeUrl =
              actualDevice === "ios" ? IOSUpdateUrl : AndroidUpdateUrl;
            alert(
              "It seems the app did not open or the app is not installed on your device. You will be redirected to the app store."
            );
            window.location.href = storeUrl;
          }
        }, 1500); // Set timeout to 1.5 seconds

        // Clear the timeout if the user successfully opens the app
        window.addEventListener("focus", () => {
          clearTimeout(timeout);
        });
      } catch (error) {
        // If there’s an error when trying to open the app, redirect to the app store
        if (actualDevice === "android" || actualDevice === "ios") {
          const storeUrl =
            actualDevice === "ios" ? IOSUpdateUrl : AndroidUpdateUrl;
          alert(
            "It seems the app did not open or the app is not installed on your device. You will be redirected to the app store."
          );
          window.location.href = storeUrl;
        }
      }
    };

    // Open app on mount
    openApp();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="flex items-center justify-center min-h-screen p-4 bg-gradient-to-br from-pink-400 via-purple-500 to-indigo-600">
      <div className="w-full max-w-md p-8 text-center bg-white rounded-lg shadow-lg">
        <Smartphone className="mx-auto mb-4 text-purple-600" size={48} />
        <h1 className="mb-2 text-2xl font-bold text-gray-800">
          Get the DesiBazaarApp
        </h1>
        <p className="mb-6 text-gray-600">
          Experience the best of DesiBazaar on your mobile device. Download our
          app for exclusive deals and a seamless shopping experience.
        </p>

        {deviceType === "ios" && (
          <button
            className="flex items-center justify-center w-full px-4 py-3 mb-4 font-semibold text-white bg-black rounded-lg"
            onClick={() =>
              (window.location.href = `desibazaarapp://product/${params?.id}`)
            }
          >
            <Apple className="mr-2" size={24} />
            <span>{params?.id ? "Open Product in App" : "Open in App"}</span>
          </button>
        )}

        {deviceType === "android" && (
          <button
            className="flex items-center justify-center w-full px-4 py-3 mb-4 font-semibold text-gray-600 bg-gray-300 rounded-lg cursor-not-allowed"
            disabled
          >
            <ShoppingBag className="mr-2" size={24} />
            <span>Coming Soon on Google Play</span>
          </button>
        )}

        {deviceType === "other" && (
          <>
            <button
              className="flex items-center justify-center w-full px-4 py-3 mb-4 font-semibold text-white bg-black rounded-lg"
              onClick={() => window.open(IOSUpdateUrl, "_blank")}
            >
              <Apple className="mr-2" size={24} />
              <span>Download for iOS</span>
            </button>
            <button
              className="flex items-center justify-center w-full px-4 py-3 mb-4 font-semibold text-gray-600 bg-gray-300 rounded-lg cursor-not-allowed"
              disabled
            >
              <ShoppingBag className="mr-2" size={24} />
              <span>Coming Soon on Google Play</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const Product: React.FC = () => {
  return <AppPromotion />;
};

export default Product;
