import { Tabs, TabsProps } from "antd";
import React from "react";
import Security from "./security";

const Profile = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Security",
      children: <Security />
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default Profile;
