import { Button, Modal, Table, TableProps } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { openNotificationWithIcon } from "../../../../utils/helper";
import useCategories, {
  category,
} from "../../../../customhooks/useCategories/useCategories";
import { getCategoriesFilter } from "../../../../api/categories/categories-interface";
import {
  deleteCategory,
  restoreCategory,
} from "../../../../api/categories/categories";
import Createcategory from "./createcategory";

const Categories = () => {
  const { categories, loadingCategories, fetchCategories, totalDocs } =
    useCategories();

  const [workingCategoryAction, setWorkingCategoryAction] = useState(false);
  const [workingCategory, setWorkingCategory] = useState<category | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [createCategoryModal, setCreateCategoryModal] = useState(false);
  const [modalMode, setModalMode] = useState<"edit" | "create">("create");

  const requestPayload: getCategoriesFilter = useMemo(() => {
    return {
      page: currentPage,
      limit: currentLimit,
      adminUse: true,
    };
  }, [currentPage, currentLimit]);

  const runFetchCategories = useCallback(() => {
    console.log(requestPayload);
    fetchCategories(requestPayload);
    // eslint-disable-next-line
  }, [requestPayload]);

  useEffect(() => {
    runFetchCategories();
  }, [runFetchCategories]);

  const closeModal = () => {
    setCreateCategoryModal(false);
    runFetchCategories();
  };

  const handleCancel = () => {
    setCreateCategoryModal(false);
  };

  const runDeleteBrand = async (item: category) => {
    try {
      setWorkingCategoryAction(true);
      const res = await deleteCategory({
        categoryId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Delete Category", message);
      setWorkingCategoryAction(false);
      runFetchCategories();
    } catch (error: any) {
      setWorkingCategoryAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Delete Category", errorMessage);
    }
  };

  const runRestoreProduct = async (item: category) => {
    try {
      setWorkingCategoryAction(true);
      const res = await restoreCategory({
        categoryId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Restore Category", message);
      setWorkingCategoryAction(false);
      runFetchCategories();
    } catch (error: any) {
      setWorkingCategoryAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Restore Category", errorMessage);
    }
  };

  const columns: TableProps<category>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text}
        </span>
      ),
      fixed: "left",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (item: { firstName: string; lastName: string }) => (
        <span
          className="capitalize"
          onClick={(e) => {
            e.preventDefault();
          }}
        >{` ${item?.firstName ?? "-"} ${item?.lastName ?? "-"}`}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item: category) => (
        <div className="flex items-center gap-2">
          <Button
            type="dashed"
            size="small"
            onClick={() => {
              setWorkingCategory(item);
              setCreateCategoryModal(true);
              setModalMode("edit");
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setWorkingCategory(item);
              if (item?.active) {
                runDeleteBrand(item);
              } else {
                runRestoreProduct(item);
              }
            }}
            type={item?.active ? "dashed" : "primary"}
            className={`${item?.active ? "" : "bg-appColorBlue2"}`}
            size="small"
            danger={item?.active}
            disabled={workingCategoryAction}
            loading={
              workingCategory?._id === item?._id && workingCategoryAction
            }
          >
            {item?.active ? "Delete" : "Restore"}
          </Button>
        </div>
      ),
      fixed: "right",
    },
  ];

  return (
    <div className="mt-4">
      <div className="mb-4">
        <Button
          size="middle"
          type="primary"
          className="bg-appColorBlue2"
          onClick={() => {
            setModalMode("create");
            setCreateCategoryModal(true);
          }}
        >
          Create Category
        </Button>
      </div>

      <Table
        size="small"
        dataSource={categories}
        columns={columns}
        loading={loadingCategories}
        rowKey={(record) => record._id}
        pagination={{
          total: totalDocs,
          pageSize: currentLimit,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentLimit(pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} categories`,
        }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title={modalMode === "create" ? "Create Category" : "Edit Category"}
        open={createCategoryModal}
        onOk={handleCancel}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
        style={{ top: 20 }}
      >
        <Createcategory
          closeModal={closeModal}
          modalMode={modalMode}
          workingCategory={workingCategory}
        />
      </Modal>
    </div>
  );
};

export default Categories;
