import React, { useState } from "react";
import { recordSalesProps } from "./products-interface";
import { Alert, Button, Col, Form, Input, message, Modal, Row } from "antd";
import { recordProductSale } from "../../../../api/product/product";
import { openNotificationWithIcon } from "../../../../utils/helper";

const Recordsale = ({
  workingProduct,
  closeModal,
  open,
  workingBid,
}: recordSalesProps) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    soldPrice: "",
    shippingPrice: "",
    commission: "",
    buyerName: "",
    buyerInstagramHandle: "",
  });

  const onFinish = async (values: any) => {
    try {
      let validationComplete = true;
      let newErrors = { ...errors };

      newErrors = {
        ...newErrors,
        soldPrice:
          values?.soldPrice === undefined ||
          values?.soldPrice?.trim()?.length === 0
            ? "Sold Price is required"
            : "",
        shippingPrice:
          values?.shippingPrice === undefined ||
          values?.shippingPrice?.length === 0
            ? "Shipping Price is required"
            : "",
        commission:
          values?.commission === undefined ||
          values?.commission?.trim()?.length === 0
            ? "Commission is required"
            : "",
        buyerName:
          values?.buyerName === undefined ||
          values?.buyerName?.trim()?.length === 0
            ? "Buyer Name is required"
            : "",
        buyerInstagramHandle:
          values?.buyerInstagramHandle === undefined ||
          values?.buyerInstagramHandle?.trim()?.length === 0
            ? "Buyer Instagram Handle is required"
            : "",
      };

      setErrors(newErrors);

      if (Object.values(newErrors).some((item) => item.trim().length !== 0)) {
        validationComplete = false;
      }

      if (validationComplete) {
        setLoading(true);

        const res = await recordProductSale(
          {
            ...values,
            bidId:
              workingBid !== undefined && workingBid !== null
                ? workingBid?._id
                : undefined,
          },
          workingProduct?._id as string
        );

        const { message } = res.data;

        openNotificationWithIcon("success", `Update Product`, message);

        closeModal(true);

        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";

      const resErrors = error?.response?.data?.errors || {};
      if (Object.keys(resErrors).length > 0) {
        setErrors((prevState) => ({
          ...prevState,
          ...resErrors,
        }));
      } else {
        message.error(errorMessage);
      }
    }
  };

  return (
    <div>
      <Modal
        title={"Record Sale"}
        open={open}
        onOk={
          loading
            ? undefined
            : () => {
                closeModal();
              }
        }
        onCancel={
          loading
            ? undefined
            : () => {
                closeModal();
              }
        }
        destroyOnClose
        footer={null}
        style={{ top: 20 }}
        // width={700}
      >
        <div>
          <Alert
            // message="Product Details"
            description={
              <>
                <div>
                  <p className="font-bold">Product Details</p>
                  <p>
                    <b>Name:</b> {workingProduct?.name}
                  </p>
                  <p>
                    <b>ID:</b> {workingProduct?.selfId}
                  </p>
                  <p>
                    <b>Product Link:</b>{" "}
                    <a
                      href={`https://desibazaarapp.com/product/${workingProduct?.selfId}`}
                    >
                      {`https://desibazaarapp.com/product/${workingProduct?.selfId}`}
                    </a>
                  </p>
                </div>

                <div className="mt-8">
                  <p className="font-bold">Seller Details</p>
                  <p>
                    <b>Name:</b> {workingProduct?.createdBy?.lastName}{" "}
                    {workingProduct?.createdBy?.firstName}
                  </p>
                  <p>
                    <b>Email:</b> {workingProduct?.createdBy?.email}
                  </p>
                  <p>
                    <b>Phone:</b> {workingProduct?.createdBy?.phoneNumber}
                  </p>
                  <p>
                    <b>Instagram Handle:</b>{" "}
                    {workingProduct?.createdBy?.instagramHandle}
                  </p>
                </div>
              </>
            }
            type="info"
          />
        </div>
        <div className="my-4">
          <p>Fill in the correct sales details below</p>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                validateStatus={errors?.soldPrice.length ? "error" : undefined}
                help={errors?.soldPrice.length ? errors?.soldPrice : undefined}
                name="soldPrice"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Sold Price ($)"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12} md={12} xs={24}>
              <Form.Item
                validateStatus={
                  errors?.shippingPrice.length ? "error" : undefined
                }
                help={
                  errors?.shippingPrice.length
                    ? errors?.shippingPrice
                    : undefined
                }
                name="shippingPrice"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Shipping Price ($)"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12} md={12} xs={24}>
              <Form.Item
                validateStatus={errors?.commission.length ? "error" : undefined}
                help={
                  errors?.commission.length ? errors?.commission : undefined
                }
                name="commission"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Commission ($)"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12} md={12} xs={24}>
              <Form.Item
                validateStatus={errors?.buyerName.length ? "error" : undefined}
                help={errors?.buyerName.length ? errors?.buyerName : undefined}
                name="buyerName"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Buyer Name"
              >
                <Input
                // placeholder="Description"
                />
              </Form.Item>
            </Col>

            <Col lg={12} md={12} xs={24}>
              <Form.Item
                validateStatus={
                  errors?.buyerInstagramHandle.length ? "error" : undefined
                }
                help={
                  errors?.buyerInstagramHandle.length
                    ? errors?.buyerInstagramHandle
                    : undefined
                }
                name="buyerInstagramHandle"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Buyer Instagram Handle"
              >
                <Input
                // placeholder="Description"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              className="bg-appColorBlue2"
              disabled={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Recordsale;
