import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getBidsFilter } from "../../../../api/product/product-interface";
import { fetchBids, markBidAsCompleted } from "../../../../api/product/product";
import { bid, product } from "../../../../customhooks/useProducts/useProducts";
import { Alert, Button, Table, TableProps, Tag } from "antd";
import moment from "moment";
import { openNotificationWithIcon } from "../../../../utils/helper";
import Recordsale from "../products/recordsale";

const Offers = () => {
  const [loading, setLoading] = useState(false);
  const [bids, setBids] = useState<bid[]>([]);
  const [totalDocs, setTotalDocs] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [workingBid, setWorkingBid] = useState<bid | null>(null);
  const [workingBidAction, setWorkingBidAction] = useState(false);
  const [workingProduct, setWorkingProduct] = useState<product | null>(null);
  const [showRecordSale, setShowRecordSale] = useState(false);

  const requestPayload: getBidsFilter = useMemo(() => {
    return {
      page: currentPage,
      limit: currentLimit,
      adminUse: true,
    };
  }, [currentPage, currentLimit]);

  const runFetchBid = useCallback(async () => {
    console.log(requestPayload);
    try {
      setLoading(true);
      const res = await fetchBids(requestPayload);
      const { docs, totalDocs } = res.data?.data;
      setTotalDocs(totalDocs);
      setBids(docs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // eslint-disable-next-line
  }, [requestPayload]);

  useEffect(() => {
    runFetchBid();
  }, [runFetchBid]);

  const closeRecordSaleModal = (refresh?: boolean) => {
    if (refresh) {
      runFetchBid();
    }
    setShowRecordSale(false);
    setWorkingProduct(null);
    setWorkingBid(null);
  };

  const runMarkBidAsCompleted = async (item: bid) => {
    try {
      setWorkingBidAction(true);
      const res = await markBidAsCompleted({
        bidId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Mark Bid As Completed", message);
      setWorkingBidAction(false);
      runFetchBid();
      setWorkingBid(null);
    } catch (error: any) {
      setWorkingBidAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Mark Bid As Completed", errorMessage);
    }
  };

  const columns: TableProps<bid>["columns"] = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Instagram",
      dataIndex: "instagramHandle",
      key: "instagramHandle",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <span onClick={() => {}}>{text}</span>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text: product) => (
        <span className="capitalize" onClick={() => {}}>
          {text?.name}
        </span>
      ),
    },
    {
      title: "Offered ($)",
      dataIndex: "history",
      key: "history",
      render: (
        text: {
          itemCount: number;
          price: number;
          dateSubmitted: string;
          _id: string;
        }[]
      ) => (
        <span className="capitalize" onClick={() => {}}>
          {text?.[0]?.price ?? "-"}
        </span>
      ),
    },
    // {
    //   title: "Whatsapp Msg Delivered",
    //   dataIndex: "messageDetails",
    //   key: "messageDetails",
    //   render: (text: {
    //     from: string;
    //     to: string;
    //     provider: string;
    //     service: string;
    //     accountSid: string;
    //     messagingServiceSid: null;
    //     sid: string;
    //     status: string;
    //   }) => (
    //     <div>
    //       {text ? (
    //         <Tag color="green">Delivered</Tag>
    //       ) : (
    //         <Tag color="red">Not Delivered</Tag>
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <div>
          {text === "completed" ? (
            <Tag color="green">Completed</Tag>
          ) : (
            <Tag color="warning">Pending</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
    {
      title: "Converted",
      dataIndex: "converted",
      key: "converted",
      render: (text: boolean) => (
        <div>
          {text ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item: bid) => (
        <div>
          <div className="flex items-center gap-2">
            <Button
              onClick={() => {
                setWorkingBid(item);
                runMarkBidAsCompleted(item);
              }}
              type="primary"
              className="bg-appColorBlue2"
              size="small"
              disabled={workingBidAction || item.status === "completed"}
              loading={workingBid?._id === item?._id && workingBidAction}
            >
              Mark as completed
            </Button>

            <Button
              type="primary"
              size="small"
              className="bg-appColorBlue2"
              onClick={() => {
                setWorkingBid(item);
                setWorkingProduct(item?.product);
                setShowRecordSale(true);
              }}
              disabled={workingBidAction || item?.product?.isSold}
            >
              Record Sale
            </Button>
          </div>
        </div>
      ),
      fixed: "right",
    },
  ];

  return (
    <div className="mt-4">
      <Table
        size="small"
        dataSource={bids}
        columns={columns}
        loading={loading}
        rowKey={(record) => record._id}
        pagination={{
          total: totalDocs,
          pageSize: currentLimit,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentLimit(pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} offers`,
        }}
        scroll={{ x: "max-content" }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="pl-12">
              <Alert
                // message="Product Details"
                description={
                  <>
                    <div>
                      <p className="font-bold">Product Details</p>
                      <p>
                        <b>Name:</b> {record?.product?.name}
                      </p>
                      <p>
                        <b>ID:</b> {record?.product?.selfId}
                      </p>
                      <p>
                        <b>Selling Price ($):</b>{" "}
                        {record?.product?.sellingPrice ?? "-"}
                      </p>
                      <p>
                        <b>Buying Price ($):</b>{" "}
                        {record?.product?.buyingPrice ?? "-"}
                      </p>
                      <p>
                        <b>Final Sell Price ($):</b>{" "}
                        {record?.product?.price ?? "-"}
                      </p>
                      <p>
                        <b>Product Link:</b>{" "}
                        <a
                          href={`https://desibazaarapp.com/product/${record?.product?.selfId}`}
                        >
                          {`https://desibazaarapp.com/product/${record?.product?.selfId}`}
                        </a>
                      </p>
                    </div>

                    <div className="mt-8">
                      <p className="font-bold">Seller Details</p>
                      <p>
                        <b>Name:</b> {record?.product?.createdBy?.lastName}{" "}
                        {record?.product?.createdBy?.firstName}
                      </p>
                      <p>
                        <b>Email:</b> {record?.product?.createdBy?.email}
                      </p>
                      <p>
                        <b>Phone:</b> {record?.product?.createdBy?.phoneNumber}
                      </p>
                      <p>
                        <b>Instagram Handle:</b>{" "}
                        {record?.product?.createdBy?.instagramHandle}
                      </p>
                    </div>
                  </>
                }
                type="info"
              />
            </div>
          ),
        }}
      />

      {showRecordSale && (
        <Recordsale
          workingProduct={workingProduct}
          closeModal={closeRecordSaleModal}
          open={showRecordSale}
          workingBid={workingBid}
        />
      )}
    </div>
  );
};

export default Offers;
