import { Alert, Button, message, Table, TableProps, Tag } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import useProducts, {
  product,
} from "../../../../customhooks/useProducts/useProducts";
import { getProductsFilter } from "../../../../api/product/product-interface";
import { brand } from "../../../../customhooks/useBrands/useBrands";
import { category } from "../../../../customhooks/useCategories/useCategories";
import moment from "moment";
import { openNotificationWithIcon } from "../../../../utils/helper";
import { deleteProduct, restoreProduct } from "../../../../api/product/product";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import Recordsale from "./recordsale";

const Products = () => {
  const { loadingProducts, products, fetchProducts, totalDocs } = useProducts();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  //@ts-ignore
  // eslint-disable-next-line
  const [currentCategoryId, setCurrentCategoryId] = useState(undefined);

  const [workingProductAction, setWorkingProductAction] = useState(false);
  const [workingProduct, setWorkingProduct] = useState<product | null>(null);
  const [showRecordSale, setShowRecordSale] = useState(false);

  const requestPayload: getProductsFilter = useMemo(() => {
    return {
      categoryId: currentCategoryId,
      page: currentPage,
      limit: currentLimit,
      adminUse: true,
    };
  }, [currentPage, currentCategoryId, currentLimit]);

  const runFetchProducts = useCallback(() => {
    console.log(requestPayload);
    fetchProducts(requestPayload);
    // eslint-disable-next-line
  }, [requestPayload]);

  useEffect(() => {
    runFetchProducts();
  }, [runFetchProducts]);

  const closeRecordSaleModal = (refresh?: boolean) => {
    if (refresh) {
      runFetchProducts();
    }
    setShowRecordSale(false);
    setWorkingProduct(null);
  };

  const runDeleteProduct = async (item: product) => {
    try {
      setWorkingProductAction(true);
      const res = await deleteProduct({
        productId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Delete Product", message);
      setWorkingProductAction(false);
      runFetchProducts();
			setWorkingProduct(null)
    } catch (error: any) {
      setWorkingProductAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Delete Product", errorMessage);
    }
  };

  const runRestoreProduct = async (item: product) => {
    try {
      setWorkingProductAction(true);
      const res = await restoreProduct({
        productId: item?._id,
      });

      const { message } = res.data;

      openNotificationWithIcon("success", "Restore Product", message);
      setWorkingProductAction(false);
      runFetchProducts();
			setWorkingProduct(null)
    } catch (error: any) {
      setWorkingProductAction(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong";
      openNotificationWithIcon("error", "Restore Product", errorMessage);
    }
  };

  const columns: TableProps<product>["columns"] = [
    {
      title: "ID",
      dataIndex: "selfId",
      key: "selfId",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text ?? "-"}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text ?? "-"}
        </span>
      ),
    },
    {
      title: "Price ($)",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "categories",
      key: "categories",
      render: (items: category[]) => (
        <div>
          {items.length === 0 ? (
            "-"
          ) : (
            <>
              {items.map((item) => (
                <Tag
                  color="geekblue"
                  className="capitalize cursor-pointer"
                  key={item?._id}
                >
                  {item?.name}
                </Tag>
              ))}
            </>
          )}
        </div>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brands",
      key: "brands",
      render: (items: brand[]) => (
        <div>
          {items.length === 0 ? (
            "-"
          ) : (
            <>
              {items.map((item) => (
                <Tag
                  color="purple"
                  className="capitalize cursor-pointer"
                  key={item?._id}
                >
                  {item?.name}
                </Tag>
              ))}
            </>
          )}
        </div>
      ),
    },
    {
      title: "Sold",
      dataIndex: "isSold",
      key: "isSold",
      render: (text: boolean) => (
        <div>
          {text ? (
            <Tag color="default">Yes</Tag>
          ) : (
            <Tag color="processing">No</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Available",
      dataIndex: "isAvailable",
      key: "isAvailable",
      render: (text: boolean) => (
        <div>
          {text ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
        </div>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (item: { firstName: string; lastName: string }) => (
        <span
          className="capitalize"
          onClick={(e) => {
            e.preventDefault();
          }}
        >{`${item?.firstName} ${item?.lastName}`}</span>
      ),
    },
    {
      title: "Sold Price ($)",
      dataIndex: "soldPrice",
      key: "soldPrice",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text ?? "-"}
        </span>
      ),
    },
    {
      title: "Shipping Price ($)",
      dataIndex: "shippingPrice",
      key: "shippingPrice",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text ?? "-"}
        </span>
      ),
    },
    {
      title: "Commission ($)",
      dataIndex: "commission",
      key: "commission",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text ?? "-"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (item: product) => (
        <div className="flex items-center gap-2">
          <Button
            type="dashed"
            size="small"
            onClick={() => {
              navigate(
                `/admin/dashboard/products/edit?product_id=${item?._id}`
              );
            }}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setWorkingProduct(item);
              if (item?.active) {
                runDeleteProduct(item);
              } else {
                runRestoreProduct(item);
              }
            }}
            type={item?.active ? "dashed" : "primary"}
            className={`${item?.active ? "" : "bg-appColorBlue2"}`}
            size="small"
            danger={item?.active}
            disabled={workingProductAction}
            loading={workingProduct?._id === item?._id && workingProductAction}
          >
            {item?.active ? "Delete" : "Restore"}
          </Button>
          <Button
            type="primary"
            size="small"
            className="bg-appColorBlue2"
            onClick={() => {
              setWorkingProduct(item);
              setShowRecordSale(true);
            }}
            disabled={workingProductAction || item?.isSold}
          >
            Record Sale
          </Button>
          <CopyToClipboard
            text={`https://desibazaarapp.com/product/${item?.selfId}`}
            onCopy={() =>
              message.success("Product link has been copied to clipboard")
            }
          >
            <Button size="small" type="default">
              Copy Link
            </Button>
          </CopyToClipboard>
        </div>
      ),
      fixed: "right",
    },
  ];

  return (
    <div className="mt-4">
      <div className="mb-4">
        <Button
          size="middle"
          type="primary"
          className="bg-appColorBlue2"
          onClick={() => {
            navigate("/admin/dashboard/products/create");
          }}
        >
          Create Product
        </Button>
      </div>
      <Table
        size="small"
        dataSource={products}
        columns={columns}
        loading={loadingProducts}
        rowKey={(record) => record._id}
        pagination={{
          total: totalDocs,
          pageSize: currentLimit,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentLimit(pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} products`,
        }}
        scroll={{ x: "max-content" }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="pl-12">
              <Alert
                // message="Product Details"
                description={
                  <>
                    <div>
                      <p className="font-bold">Other Product Details</p>
                      <p>
                        <b>Buying Price ($):</b> {record?.buyingPrice ?? "-"}
                      </p>
                      <p>
                        <b>Final Sell Price ($):</b> {record?.price ?? "-"}
                      </p>
                    </div>

                    {record?.isSold ? (
                      <div className="mt-8">
                        <p className="font-bold">Buyer Details</p>
                        <p>
                          <b>Name:</b> {record?.buyerName ?? "-"}
                        </p>
                        <p>
                          <b>Instagram Handle:</b>{" "}
                          {record?.buyerInstagramHandle ?? "-"}
                        </p>
                        <p>
                          <b>Sold Price ($):</b> {record?.soldPrice ?? "-"}
                        </p>
                        <p>
                          <b>Shipping Price ($):</b>{" "}
                          {record?.shippingPrice ?? "-"}
                        </p>
                        <p>
                          <b>Commission ($):</b> {record?.commission ?? "-"}
                        </p>
                        <p>
                          <b>Date Sold:</b>{" "}
                          {record?.dateSold
                            ? moment(record?.dateSold).format("LL")
                            : "-"}
                        </p>
                      </div>
                    ) : (
                      <div className="mt-8">
                        <Tag className="font-bold" color="red">
                          Item has not yet being sold
                        </Tag>
                      </div>
                    )}
                  </>
                }
                type="info"
              />
            </div>
          ),
        }}
      />

      {showRecordSale && (
        <Recordsale
          workingProduct={workingProduct}
          closeModal={closeRecordSaleModal}
          open={showRecordSale}
        />
      )}

      {/* <Modal
        title={modalMode === "create" ? "Create Product" : "Edit Product"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
        style={{ top: 20 }}
        width={700}
      >
        <div>
          <Createproduct
            loadingBrands={loadingBrands}
            brands={brands}
            loadingCategories={loadingCategories}
            categories={categories}
            handleCloseModal={handleCloseModal}
            modalMode={modalMode}
            workingProduct={workingProduct}
          />
        </div>
      </Modal> */}
    </div>
  );
};

export default Products;
