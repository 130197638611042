import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Homepage from "./pages/homepage/homepage";
// import StripeAccountLinkingRefresh from "./pages/stripeaccountlinking/stripeaccountlinkingrefresh";
// import StripeAccountLinkingSuccess from "./pages/stripeaccountlinking/stripeaccountlinkingsuccess";
import { ConfigProvider, ThemeConfig } from "antd";
import Adminlogin from "./pages/admin/adminlogin/adminlogin";
import Admindashboard from "./pages/admin/admindashboard/admindashboard";
import Protectedroute from "./components/protectedroutes/protectedroute";
import Dashboardhome from "./pages/admin/admindashboard/dashboardhome/dashboardhome";
import Users from "./pages/admin/admindashboard/users/users";
import Products from "./pages/admin/admindashboard/products/products";
import Createproduct from "./pages/admin/admindashboard/products/createproduct";
import Brands from "./pages/admin/admindashboard/brands/brands";
import { setAuthToken } from "./api";
import { RootState } from "./store/store";
import { useSelector } from "react-redux";
import Categories from "./pages/admin/admindashboard/categories/categories";
import Offers from "./pages/admin/admindashboard/offers/offers";
import Questions from "./pages/admin/admindashboard/questions/questions";
import Sell from "./pages/sell/sell";
import Privacy from "./pages/privacy/privacy";
import Product from "./pages/product/product";
import Profile from "./pages/admin/admindashboard/profile/profile";

function App() {
  const state = useSelector((state: RootState) => ({
    token: state.user.token,
  }));

  const { token } = state;
  setAuthToken(token);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />,
    },
		{
      path: "/product/:id",
      element: <Product />,
    },
    // {
    //   path: "/account-link-refresh",
    //   element: <StripeAccountLinkingRefresh />,
    // },
    // {
    //   path: "/account-link-success",
    //   element: <StripeAccountLinkingSuccess />,
    // },
		{
      path: "/sell",
      element: <Sell />,
    },
		{
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/admin",
      element: <Adminlogin />,
    },
    {
      element: <Protectedroute />,
      children: [
        {
          path: "/admin/dashboard",
          element: <Admindashboard />,
          children: [
            {
              path: "/admin/dashboard",
              element: <Dashboardhome />,
            },
            {
              path: "/admin/dashboard/users",
              element: <Users />,
            },
            {
              path: "/admin/dashboard/products",
              element: <Products />,
            },
            {
              path: "/admin/dashboard/products/create",
              element: <Createproduct />,
            },
            {
              path: "/admin/dashboard/products/edit",
              element: <Createproduct />,
            },
            {
              path: "/admin/dashboard/brands",
              element: <Brands />,
            },
						{
              path: "/admin/dashboard/categories",
              element: <Categories />,
            },
						{
              path: "/admin/dashboard/questions",
              element: <Questions />,
            },
						{
              path: "/admin/dashboard/offers",
              element: <Offers />,
            },
						{
              path: "/admin/dashboard/profile",
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ]);

  const config: ThemeConfig = {
    token: {
      colorPrimary: "#0071c5",
    },
  };

  return (
    <ConfigProvider theme={config}>
      <div className="App">
        <Suspense>
          <RouterProvider router={router} />
        </Suspense>
      </div>
    </ConfigProvider>
  );
}

export default App;
