import { Button, Table, TableProps, Tag } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { openNotificationWithIcon } from "../../../../utils/helper";
import {
  getPasskeys,
  passkeyRegisterOptions,
  passkeyRegisterVerify,
} from "../../../../api/auth/auth";
import { startRegistration } from "@simplewebauthn/browser";
import { getPasskeysFilter } from "../../../../api/auth/auth-interface";
import moment from "moment";

const Security = () => {
  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const [registering, setRegistering] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setCurrentLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [data, setData] = useState([]);

  const requestPayload: getPasskeysFilter = useMemo(() => {
    return {
      page: currentPage,
      limit: currentLimit,
      userId: user?._id,
      // adminUse: true,
    };
  }, [currentPage, currentLimit]);

  // Handle passkey registration
  const handleRegisterPasskey = async () => {
    try {
      setRegistering(true);

      const optionsRes = await passkeyRegisterOptions({
        userId: user?._id,
      });

      const { success, options } = optionsRes.data;
      if (!success) {
        throw new Error("Something went wrong");
      }

      console.log(options);

      const attResp = await startRegistration({ optionsJSON: options });

      const verifyRes = await passkeyRegisterVerify({
        ...attResp,
      });

      const { success: verifySuccess, message } = verifyRes.data;

      if (verifySuccess) {
        openNotificationWithIcon(
          "success",
          "Passkey",
          "Passkey registered successfully"
        );

        fetchPasskeys();
        // Refresh the list
      } else {
        throw new Error(message ?? "Something went wrong");
      }

      setRegistering(false);
    } catch (error: any) {
      setRegistering(false);
      console.error("Error registering passkey:", error);
      openNotificationWithIcon(
        "error",
        "Passkey",
        `Failed to register passkey: ${error.message}`
      );
    }
  };

  const fetchPasskeys = useCallback(async () => {
    try {
      const res = await getPasskeys(requestPayload);
      const { docs } = res.data?.data;
      setData(docs);
      setLoading(false);
      setTotalDocs(totalDocs);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  }, [requestPayload]);

  useEffect(() => {
    fetchPasskeys();
  }, [fetchPasskeys]);

  const columns: TableProps<any>["columns"] = [
    {
      title: "Device Name",
      dataIndex: "deviceName",
      key: "deviceName",
      render: (text) => (
        <span className="capitalize" onClick={() => {}}>
          {text}
        </span>
      ),
      fixed: "left",
    },
    // {
    //   title: "Login Counter",
    //   dataIndex: "counter",
    //   key: "counter",
    //   render: (text) => (
    //     <span className="capitalize" onClick={() => {}}>
    //       {text}
    //     </span>
    //   ),
    //   fixed: "left",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <div>
          {text === "active" ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red" className="capitalize">
              {text}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <span>{moment(text).format("LL")}</span>,
    },
  ];

  return (
    <div>
      <div className="mb-2">
        <h3 className="mb-2 font-bold">Passkey</h3>
        <Button
          loading={registering}
          disabled={registering}
          type="primary"
          className="bg-appColorBlue2"
          onClick={() => {
            handleRegisterPasskey();
          }}
        >
          Create Passkey
        </Button>
      </div>

      <Table
        size="small"
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey={(record) => record._id}
        pagination={{
          total: totalDocs,
          pageSize: currentLimit,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentLimit(pageSize);
          },
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} passkeys`,
        }}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default Security;
