import React from "react";
import Createproduct from "../admin/admindashboard/products/createproduct";

const Sell = () => {
  return (
    <div className="px-6 pb-12 linear-bg-one lg:px-8 overscroll-y">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="w-auto h-12 mx-auto"
          src="/img/logo/logo.png"
          alt="Your Company"
        />
        <h2 className="mt-2 text-2xl font-bold leading-9 tracking-tight text-center text-gray-900">
          Sell
        </h2>

        <div className="text-[14px]">
          <span className="block font-semibold text-center">
            The listed price should include our 16% commission
          </span>
          <span className="block text-center opacity-[0.6]">
            Shipping is paid by the buyer
          </span>
          <span className="block font-semibold text-center">
            Make sure the used dresses are dry cleaned/washed
          </span>
          <span className="block text-center opacity-[0.6]">
            Please fill out the form separately for each article you want to
            sell
          </span>
        </div>
      </div>

      <div className="w-full bg-white rounded-md shadow-md lg:w-[70%] md:w-[70%] mx-auto mt-10">
        <Createproduct originatingFrom="/sell" />
      </div>
    </div>
  );
};

export default Sell;
